/**
 * @prettier
 */

if (document.querySelector('.js-data-model-site')) {
  window.Site = JSON.parse(document.querySelector('.js-data-model-site').dataset.value)
}
if (document.querySelector('.js-data-model-account')) {
  window.Account = JSON.parse(
    document.querySelector('.js-data-model-account').dataset.value,
  )
}
if (document.querySelector('.js-data-model-campaign')) {
  window.Campaign = JSON.parse(
    document.querySelector('.js-data-model-campaign').dataset.value,
  )
}
if (document.querySelector('.js-data-model-view-setup')) {
  window.ViewSetup = JSON.parse(
    document.querySelector('.js-data-model-view-setup').dataset.value,
  )
}
if (document.querySelector('.js-data-model-view-preset')) {
  window.ViewPreset = JSON.parse(
    document.querySelector('.js-data-model-view-preset').dataset.value,
  )
}
if (document.querySelector('.js-data-model-current-user')) {
  window.current_user = JSON.parse(
    document.querySelector('.js-data-model-current-user').dataset.value,
  )
}
